import axios from 'axios';
import React from 'react';
import { IntlProvider } from 'react-intl';

let preloadedData;

try {
  preloadedData = JSON.parse(window.atob(window.__PAGE_DATA__));
} catch {
  console.warn('No page data resolved, falling back to async query.');
  preloadedData = null;
}

const PageDataContext = React.createContext();

export function PageDataProvider({ children }) {
  const clientLang = window.navigator.language.split('-')[0];
  const pageData = useProvideData();
  const messages = pageData.messages
    ? pageData.messages.reduce((values, currentValue) => {
        values[currentValue.key] = currentValue[clientLang] || currentValue.en;
        return values;
      }, {})
    : [];
  return (
    <PageDataContext.Provider value={pageData}>
      <IntlProvider locale="en" messages={messages}>
        {children}
      </IntlProvider>
    </PageDataContext.Provider>
  );
}

function useProvideData() {
  const [pageData, setPageData] = React.useState(preloadedData || {});
  React.useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const fetchPageData = async () => {
      const domain = searchParams.get('domain') || 'naming.ai';
      const { data } = await axios.get(`/index.php?domain=${domain}`, {
        headers: {
          Accept: 'application/json',
        },
      });
      setPageData(data);
    };
    if (!preloadedData) {
      fetchPageData();
    }
  }, []);
  return pageData;
}

export default () => {
  return React.useContext(PageDataContext);
};
