import cx from 'classnames';
import React from 'react';

function TextInput(
  {
    className,
    label,
    inputClassName,
    innerLeft,
    innerRight,
    type = 'text',
    variant,
    ...inputProps
  },
  ref,
) {
  return (
    <label className={cx('flex items-center', className)}>
      <div
        className={cx(
          'w-full pill px-6 relative h-16 flex items-center bg-white overflow-hidden',
          {
            'text-lg md:text-xl': variant !== 'large',
            'text-xl md:text-2xl': variant === 'large',
          },
        )}>
        {(label || innerLeft) && (
          <div className="-ml-6 flex-shrink-0 self-stretch flex items-center">
            {label && (
              <div className="w-32 px-2 mr-4 self-stretch flex items-center justify-center bg-gray-900 text-white text-base">
                {label}
              </div>
            )}
            {innerLeft}
          </div>
        )}
        <div className="flex flex-auto self-stretch py-1">
          <input
            className={cx(
              inputClassName,
              'w-full px-1 my-1 self-stretch flex-auto bg-transparent focus:outline-none rounded-lg',
            )}
            ref={ref}
            type={type}
            {...inputProps}
          />
        </div>
        {innerRight && <div className="-mr-6 ml-1">{innerRight}</div>}
      </div>
    </label>
  );
}

export default React.forwardRef(TextInput);
