import cx from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as ChevronLeft } from '../assets/chevron-left.svg';
import { ReactComponent as ChevronRight } from '../assets/chevron-right.svg';
import usePageData from '../lib/usePageData';

const scrollOffset = 15;

export default function NewsTicker() {
  const scrollArea = React.useRef();
  const [showNextButton, setShowNextButton] = React.useState(true);
  const [showPrevButton, setShowPrevButton] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const { news, tld } = usePageData();

  React.useEffect(() => {
    // Hide broken images
    if (!scrollArea.current) {
      return;
    }
    scrollArea.current.querySelectorAll('img').forEach(node => {
      node.onerror = function () {
        this.style.display = 'none';
      };
    });
    // Update page as user scrolls
    scrollArea.current.addEventListener('scroll', event => {
      setShowPrevButton(scrollArea.current.scrollLeft > 0);
      setPage(
        Math.floor(
          (scrollArea.current.scrollLeft + scrollOffset) /
            scrollArea.current.clientWidth,
        ),
      );
    });
    // Hide the next arrow when the final news item is intersecting
    const observer = new IntersectionObserver(
      entries => {
        setShowNextButton(!entries[0].isIntersecting);
      },
      {
        root: scrollArea.current,
      },
    );
    scrollArea.current
      .querySelectorAll(':scope > *:last-child')
      .forEach(node => observer.observe(node));
  }, []);

  if (news.length === 0) {
    return null;
  }

  const onClickNext = event => {
    const nextPage = page + 1;
    scrollArea.current.scroll({
      left: scrollArea.current.clientWidth * nextPage - scrollOffset,
      behavior: 'smooth',
    });
    setPage(nextPage);
  };

  const onClickPrev = event => {
    let nextPage = page;
    if (
      (scrollArea.current.scrollLeft + scrollOffset) %
        scrollArea.current.clientWidth ===
      0
    ) {
      nextPage = page - 1;
    }
    scrollArea.current.scroll({
      left: scrollArea.current.clientWidth * nextPage - scrollOffset,
      behavior: 'smooth',
    });
    setPage(nextPage);
  };

  return (
    <div className="h-28 sm:h-32">
      <div className="md:fixed bottom-0 w-full">
        <div className="relative flex text-left bg-gray-450">
          <div
            className="
              flex-shrink-0 flex items-center justify-center
              w-12 md:w-16 h-28 sm:h-32
              text-gray-900 text-xs font-bold bg-gray-800 text-white">
            <div
              className="
              transform origin-center -rotate-90 tracking-widest whitespace-no-wrap uppercase">
              <FormattedMessage
                defaultMessage="{tld} news"
                id="NEWS_HEADING"
                values={{ tld }}
              />
            </div>
          </div>
          <div className="flex overflow-hidden -mt-2">
            <div
              className="relative overflow-auto flex -mb-4 pt-2 pb-4"
              ref={scrollArea}>
              {news.map((item, index) => (
                <a
                  className="
                  relative lg:hover:z-10 flex-shrink-0 flex items-center
                  p-3 sm:pr-6 pl-4 sm:pl-8 sm:w-7/12 lg:w-1/3 max-w-10/12
                  bg-gray-450 text-gray-900 shadow-2xl lg:rounded-lg
                  transform transition ease duration-300 lg:hover:-translate-y-2"
                  href={item.link}
                  key={index}>
                  <div className="flex-shrink-0">
                    <img
                      className="
                      block w-16 sm:w-20 h-16 sm:h-20 mr-4 sm:mr-6
                      shadow rounded-lg object-contain bg-white border-4 border-white border-solid"
                      alt=""
                      src={
                        item.thumbnail ||
                        `//logo.clearbit.com/${item.title}?size=160`
                      }
                    />
                  </div>
                  <div className="w-64">
                    <div className="inline-block sm:mb-2 text-xs sm:text-sm uppercase leading-tight tracking-widest font-bold">
                      {item.title}
                    </div>
                    <div className="line-clamp text-xs sm:text-sm text-white leading-tight">
                      {item.excerpt}
                    </div>
                  </div>
                </a>
              ))}
            </div>
          </div>
          <div
            className={cx(
              'absolute z-20 top-0 left-0 bottom-0 hidden',
              'w-12 ml-16 justify-start items-center overflow-hidden',
              { 'lg:flex': showPrevButton },
            )}
            style={{
              background:
                'linear-gradient(to left, hsla(200, 5%, 50%, 0), hsla(200, 5%, 50%, 1))',
            }}>
            <button
              className="inline-flex items-center justify-center w-10 text-white bg-gray-800 rounded-r-full"
              onClick={onClickPrev}
              style={{ height: '55px' }}>
              <ChevronLeft className="-ml-2" height={40} width={40} />
            </button>
          </div>
          <div
            className={cx(
              'absolute z-20 top-0 right-0 bottom-0 hidden',
              'lg:flex w-12 justify-end items-center overflow-hidden',
              { 'lg:hidden': !showNextButton },
            )}
            style={{
              background:
                'linear-gradient(to right, hsla(200, 5%, 50%, 0), hsla(200, 5%, 50%, 1))',
            }}>
            <button
              className="inline-flex items-center justify-center w-10 text-white bg-gray-800 rounded-l-full"
              onClick={onClickNext}
              style={{ height: '55px' }}>
              <ChevronRight className="-mr-2" height={40} width={40} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
