import cx from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import usePageData from '../lib/usePageData';

export default function SimilarDomains({ children, className, domains }) {
  const { config, tld, tlds } = usePageData();
  if (!config.similarDomains || config.similarDomains.length === 0) {
    return null;
  }
  const itemClassName =
    'pill h-12 align-middle mx-1 sm:mx-2 mb-3 sm:mb-4 px-3 sm:px-4 font-display';
  return (
    <div className={cx(className, 'mt-16 px-1 sm:px-4')}>
      {children || (
        <h2 className="mb-6 text-white uppercase font-display tracking-widest text-sm">
          <FormattedMessage
            defaultMessage="Similar domains"
            id="SIMILAR_HEADING"
          />
        </h2>
      )}
      {config.similarDomains.map(item => (
        <a
          className={cx(itemClassName, 'bg-gray-650 text-white')}
          key={item}
          href={`http://${item}`}>
          {item}
        </a>
      ))}
      {tlds && tlds[tld] && (
        <a
          className={cx('bg-white text-black text-sm', itemClassName)}
          href={tlds[tld].index}
          type="button">
          Show more
        </a>
      )}
    </div>
  );
}
