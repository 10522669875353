import cx from 'classnames';
import React from 'react';

export default function OrDivider({
  bgClassName = '',
  borderClassName = '',
  borderWidth = '4px',
  className = '',
  orientation = 'horizontal',
}) {
  return (
    <div
      className={cx(className, 'relative flex justify-center items-center', {
        'transform rotate-90 origin-center': orientation === 'vertical',
      })}>
      <hr
        className={cx(
          'absolute z-0 h-0 mx-auto transform -translate-x-1/2 border-gray-475',
          borderClassName,
        )}
        style={{
          borderTopWidth: borderWidth,
          left: '0',
          width: '9999px',
        }}
      />
      <div
        className={cx(
          'relative flex-shrink-0 self-center inline-flex items-center justify-center w-12 h-12 font-display rounded-full leading-none',
          bgClassName,
          borderClassName,
          {
            'transform -rotate-90 origin-center': orientation === 'vertical',
          },
        )}
        style={{
          borderWidth: borderWidth,
        }}>
        or
      </div>
    </div>
  );
}
