import cx from 'classnames';
import _isObject from 'lodash/isObject';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import SmoothScroll from 'smoothscroll-polyfill';
import { ReactComponent as AtSign } from '../assets/at-sign.svg';
import { ReactComponent as Avatar } from '../assets/avatar.svg';
import { ReactComponent as Check } from '../assets/check.svg';
import { ReactComponent as Dialpad } from '../assets/dialpad.svg';
import formatUsd from '../lib/formatUsd';
import useOfferState from '../lib/useOfferState';
import usePageData from '../lib/usePageData';
import Button from './Button';
import CountrySelect from './CountrySelect';
import Dialog from './Dialog';
import {
  Layout,
  LayoutBottom,
  LayoutHeading,
  LayoutSubheading,
  LayoutTop,
} from './Layout';
import LoadingBar from './LoadingBar';
import NewsTicker from './NewsTicker';
import OrDivider from './OrDivider';
import PoweredByNamecheap from './PoweredByNamecheap';
import SimilarDomains from './SimilarDomains';
import TextInput from './TextInput';
import TrustBlocks from './TrustBlocks';

SmoothScroll.polyfill();

export default function OfferForm() {
  const phoneInput = React.useRef();
  const { formatMessage } = useIntl();
  const { config, domain } = usePageData();
  const [state, send] = useOfferState({ config, domain });
  const stateKey = _isObject(state) ? Object.keys(state.value)[0] : state.value;
  const {
    busy,
    code,
    country,
    email,
    name,
    offer,
    phoneUserInput,
    phoneFormatIntl,
    phoneIsValid,
  } = state.context;

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [stateKey]);

  React.useEffect(() => {
    if (domain && config) {
      send({ type: 'SET_PAGE_DATA', domain, config });
    }
  }, [config, domain, send]);

  React.useEffect(() => {
    if (!phoneInput.current) {
      return;
    }
    phoneInput.current.setCustomValidity(
      phoneIsValid ? '' : formatMessage({ id: 'ERR_INVALID_PHONE' }),
    );
  }, [formatMessage, phoneIsValid]);

  return (
    <div className={cx({ 'cursor-wait': busy })}>
      <div className="absolute w-full z-10">
        <div className={cx('lg:hidden', { invisible: !busy })}>
          <LoadingBar />
        </div>
      </div>
      <SwitchTransition>
        <CSSTransition
          addEndListener={(node, done) =>
            node.addEventListener('transitionend', done, false)
          }
          classNames="page"
          key={stateKey}>
          {state.matches('initial') ? (
            <Layout>
              <PoweredByNamecheap />
              <LayoutTop
                className="relative flex-auto"
                style={{
                  paddingTop: '8vh',
                  paddingBottom: '8vh',
                  boxShadow: '0 1px 0 999px var(--gray-600)',
                }}>
                <LayoutHeading className="mb-1" variant="domain">
                  {domain}
                </LayoutHeading>
                <LayoutSubheading variant="domain">
                  <FormattedMessage
                    defaultMessage="<b>is for sale.</b> Make an offer"
                    id="S1_SUBHEADING"
                    values={{
                      b: chunks => (
                        <span className="opacity-75">{chunks}&nbsp;</span>
                      ),
                    }}
                  />
                </LayoutSubheading>
                <div className="self-stretch">
                  <div
                    className="
                      mobile-scale overflow-hidden relative flex flex-wrap md:flex-no-wrap justify-center
                      max-w-xl md:max-w-4xl mx-auto sm:px-8 py-12 md:pt-16 md:pb-0">
                    <form
                      className="w-full md:w-auto mb-8 md:mb-0"
                      onSubmit={event => {
                        event.preventDefault();
                        send('NEXT');
                      }}>
                      <TextInput
                        className="relative z-10"
                        innerLeft={
                          <div className="pl-6 pr-2 font-display" title="USD">
                            $
                          </div>
                        }
                        innerRight={
                          <Button
                            arrowRight
                            className="h-12 mr-2 px-6 text-lg bg-blue hover:bg-blue-alt text-gray-900"
                            type="submit">
                            <FormattedMessage
                              defaultMessage="Next"
                              id="S1_BTN_SUBMIT"
                            />
                          </Button>
                        }
                        inputMode="numeric"
                        name="offer"
                        onChange={event => {
                          send({
                            type: 'INPUT_OFFER',
                            value: event.target.value,
                          });
                        }}
                        required
                        type="text"
                        value={offer.toLocaleString()}
                        variant="large"
                      />
                    </form>
                    <OrDivider
                      bgClassName="bg-gray-400"
                      borderClassName="border-gray-475"
                      className="mr-8 md:mx-8"
                    />
                    <div className="relative">
                      {state.matches('initial.showSMS') ? (
                        <>
                          <div
                            className="
                              absolute bottom-0 md:top-0 md:bottom-auto
                              w-full pt-2 md:pb-2 md:pt-0 flex items-center justify-center
                              text-white transform translate-y-full md:-translate-y-full whitespace-no-wrap">
                            <div className="opacity-75 mr-2">
                              <Dialpad
                                className="relative"
                                height={20}
                                width={20}
                                style={{ top: '1px' }}
                              />
                            </div>{' '}
                            <FormattedMessage
                              defaultMessage="Text your offer"
                              id="S1_TEXT_OFFER"
                            />
                          </div>
                          <span className="pill px-8 w-full h-16 bg-yellow text-xl">
                            {config.smsRecipient}
                          </span>
                        </>
                      ) : (
                        <a
                          className="pill px-8 h-16 md:flex-none bg-gray-900 text-white font-display font-semibold"
                          href={`sms:${config.smsRecipient.trim()}`}
                          onClick={() => send('SELECT_SMS')}>
                          <span className="text-blue mr-2">
                            <Dialpad
                              className="relative"
                              height={20}
                              width={20}
                              style={{ top: '1px' }}
                            />
                          </span>
                          <FormattedMessage
                            defaultMessage="Send via SMS"
                            id="BTN_TEXT_OFFER"
                          />
                        </a>
                      )}
                    </div>
                  </div>
                </div>
                <div className="-mt-8 sm:mt-0 md:mt-20">
                  <TrustBlocks
                    blocks={[
                      TrustBlocks.blockDefs.secure,
                      TrustBlocks.blockDefs.paymentPlan,
                      TrustBlocks.blockDefs.registrar,
                    ]}
                  />
                  <SimilarDomains className="bg-gray-600" />
                </div>
              </LayoutTop>
              <LayoutBottom>
                <NewsTicker />
              </LayoutBottom>
            </Layout>
          ) : state.matches('details') ? (
            <Layout onBack={() => send('BACK')}>
              <LayoutTop style={{ height: '260px' }}>
                <LayoutSubheading className="mt-4 mb-2 lg:mb-4">
                  <FormattedMessage
                    defaultMessage="You are making an offer on"
                    id="S2_SUBHEADING"
                  />
                </LayoutSubheading>
                <LayoutHeading className="mb-4">{domain}</LayoutHeading>
              </LayoutTop>
              <LayoutBottom>
                <div className="mx-auto sm:px-4 sm:py-12 max-w-lg">
                  <form
                    className="mobile-scale"
                    onSubmit={event => {
                      event.preventDefault();
                      send('NEXT');
                    }}>
                    <TextInput
                      className="mb-4 lg:mb-6 transform -translate-y-1/2 sm:translate-y-0"
                      innerLeft={
                        <div className="pr-2 lg:pl-2 lg:pr-3 font-display">
                          $
                        </div>
                      }
                      inputMode="numeric"
                      label={formatMessage({
                        defaultMessage: 'Your offer',
                        id: 'LABEL_OFFER',
                      })}
                      name="offer"
                      onChange={event => {
                        send({
                          type: 'INPUT_OFFER',
                          value: event.target.value,
                        });
                      }}
                      required
                      type="text"
                      value={offer.toLocaleString()}
                      variant="large"
                    />
                    <TextInput
                      className="mb-4 lg:mb-6 transform -translate-y-1/2 sm:translate-y-0"
                      innerLeft={
                        <div className="pl-6 pr-2 lg:pl-6 lg:pr-3">
                          <Avatar height={28} width={28} />
                        </div>
                      }
                      // label="Name"
                      name="name"
                      onChange={event => {
                        send({
                          type: 'INPUT_NAME',
                          value: event.target.value,
                        });
                      }}
                      placeholder={formatMessage({
                        defaultMessage: 'Your name',
                        id: 'LABEL_NAME',
                      })}
                      required
                      value={name}
                    />
                    <TextInput
                      // label="Email"
                      className="transform transform -translate-y-1/2 sm:translate-y-0"
                      innerLeft={
                        <div className="pl-6 pr-2 lg:pl-6 lg:pr-3">
                          <AtSign height={24} width={24} />
                        </div>
                      }
                      name="email"
                      onChange={event => {
                        send({
                          type: 'INPUT_EMAIL',
                          value: event.target.value,
                        });
                      }}
                      placeholder={formatMessage({
                        defaultMessage: 'email address',
                        id: 'LABEL_EMAIL',
                      })}
                      required
                      type="email"
                      value={email}
                    />
                    <Button arrowRight type="submit" variant="submit">
                      <FormattedMessage
                        defaultMessage="Next"
                        id="S2_BTN_SUBMIT"
                      />
                    </Button>
                  </form>
                </div>
              </LayoutBottom>
            </Layout>
          ) : state.matches('phone') ? (
            <Layout onBack={() => send('BACK')}>
              <LayoutTop style={{ height: '260px' }}>
                <LayoutSubheading className="mb-2 md:mb-4">
                  <FormattedMessage
                    defaultMessage="To finish up the process"
                    id="S3_SUBHEADING"
                  />
                </LayoutSubheading>
                <LayoutHeading>
                  <FormattedMessage
                    defaultMessage="Verify your number"
                    id="S3_HEADING"
                  />
                </LayoutHeading>
              </LayoutTop>
              <LayoutBottom>
                <div className="max-w-lg mx-auto sm:px-4">
                  {state.matches('phone.enterNumber') && (
                    <div className="mobile-scale sm:pt-12">
                      <CountrySelect
                        className="mb-4 md:mb-8 transform -translate-y-1/2 sm:translate-y-0"
                        value={country}
                        onChange={value => {
                          send({
                            type: 'INPUT_COUNTRY',
                            value,
                          });
                        }}
                      />
                      <form
                        onSubmit={event => {
                          event.preventDefault();
                          send('NEXT');
                        }}>
                        {country && country.phone && (
                          <>
                            <TextInput
                              className="transform -translate-y-1/2 sm:translate-y-0"
                              inputClassName="tracking-wide"
                              inputMode="numeric"
                              label={formatMessage({
                                defaultMessage: 'Phone',
                                id: 'LABEL_PHONE',
                              })}
                              name="phone"
                              onChange={event => {
                                send({
                                  type: 'INPUT_PHONE',
                                  value: event.target.value,
                                });
                              }}
                              value={phoneUserInput || ''}
                              ref={phoneInput}
                              required
                              type="tel"
                            />
                            <Button
                              arrowRight
                              disabled={state.matches(
                                'phone.enterNumber.sendingCode',
                              )}
                              id="submit-button"
                              type="submit"
                              variant="submit">
                              <FormattedMessage
                                defaultMessage="Next"
                                id="S3_BTN_SUBMIT"
                              />
                            </Button>
                          </>
                        )}
                      </form>
                    </div>
                  )}
                  {state.matches('phone.confirmCode') && (
                    <div className="mobile-scale">
                      <form
                        className="flex flex-col"
                        onSubmit={async event => {
                          event.preventDefault();
                          send('NEXT');
                        }}>
                        <TextInput
                          className="transform -translate-y-1/2 sm:translate-y-0"
                          inputClassName="tracking-widest text-3xl"
                          inputMode="numeric"
                          label="Code"
                          maxLength="10"
                          minLength="4"
                          onChange={event => {
                            send({
                              type: 'INPUT_CODE',
                              value: event.target.value,
                            });
                          }}
                          required
                          value={code}
                          variant="large"
                        />
                        <div className="sm:order-first pt-8 sm:pt-12">
                          <p className="text-lg text-white opacity-75">
                            <FormattedMessage
                              defaultMessage="Enter the verification code sent to"
                              id="S4_BODY"
                            />
                          </p>
                          <div className="mt-4 mb-8 flex flex-wrap items-center justify-center text-white">
                            <div className="w-auto mr-6 text-3xl tracking-wide whitespace-no-wrap">
                              {phoneFormatIntl}
                            </div>
                            <button onClick={() => send('BACK')} type="button">
                              <FormattedMessage
                                defaultMessage="Change"
                                id="S4_BTN_CHANGE_PHONE"
                              />
                            </button>
                          </div>
                        </div>
                        <Button
                          disabled={state.matches(
                            'phone.confirmCode.submitOffer',
                          )}
                          type="submit"
                          variant="submit">
                          <FormattedMessage
                            defaultMessage="Submit offer"
                            id="S4_BTN_SUBMIT"
                          />
                        </Button>
                      </form>
                    </div>
                  )}
                </div>
              </LayoutBottom>
            </Layout>
          ) : state.matches('thanks') ? (
            <Layout className="min-h-screen">
              <LayoutTop className="flex-auto">
                <div className="relative inline-block w-16 h-16 bg-green rounded-full">
                  <Check
                    className="absolute top-0 left-0 inline-block mt-1 ml-4"
                    height={54}
                    width={54}
                  />
                </div>
                <LayoutHeading className="p-4">
                  <FormattedMessage
                    defaultMessage="Thanks"
                    id="THANKS_HEADING"
                  />
                </LayoutHeading>
                <LayoutSubheading className="max-w-xl px-8">
                  <FormattedMessage
                    defaultMessage="We’ll get back to you soon."
                    id="THANKS_SUBHEADING"
                  />
                </LayoutSubheading>
                <SimilarDomains>
                  <h2 className="mb-6 text-white opacity-75 text-xl font-display">
                    <FormattedMessage
                      defaultMessage="You might also like these domains"
                      id="THANKS_SIMILAR_HEADING"
                    />
                  </h2>
                </SimilarDomains>
              </LayoutTop>
            </Layout>
          ) : null}
        </CSSTransition>
      </SwitchTransition>
      <Dialog
        aria-labelledby="alert-dialog-slide-title"
        open={
          state.matches('initial.lowOffer') ||
          state.matches('initial.tooLowOffer') ||
          state.matches('details.tooLowOffer')
        }
        onClose={() => send('BACK')}>
        <div className="max-w-lg p-8 md:p-12 bg-gray-900 text-white">
          <h2
            className="font-title text-3xl text-red-400 leading-tight"
            id="alert-dialog-slide-title">
            {state.matches('initial.lowOffer') ? (
              <FormattedMessage
                defaultMessage="We think your offer might be too low."
                id="LOW_OFFER_HEADING"
              />
            ) : (
              <FormattedMessage
                defaultMessage="Enter an offer amount of at least {minOffer}."
                id="ERR_INVALID_OFFER"
                values={{
                  minOffer: formatUsd(config.minimumOffer),
                }}
              />
            )}
          </h2>
          <p className="my-6 text-lg text-gray-500">
            {config.suggestedOffer && (
              <FormattedMessage
                defaultMessage="95% of similar domains sell for {minimum} or more. Consider raising your offer."
                id="LOW_OFFER_BODY"
                values={{
                  minimum: formatUsd(config.suggestedOffer),
                }}
              />
            )}
          </p>
          <div className="mt-12 md:flex">
            <Button
              className="w-full md:w-auto h-12 mb-2 md:mr-2 bg-gray-800 text-white"
              onClick={() => send('BACK')}>
              <FormattedMessage
                defaultMessage="Go back"
                id="LOW_OFFER_BTN_BACK"
              />
            </Button>
            {state.matches('initial.lowOffer') && (
              <Button
                className="w-full md:w-auto h-12 bg-gray-900 border-gray-700 border text-white"
                onClick={() => send('CONTINUE_ANYWAY')}>
                <FormattedMessage
                  defaultMessage="Continue anyway"
                  id="LOW_OFFER_BTN_CONTINUE"
                />
              </Button>
            )}
          </div>
        </div>
      </Dialog>
    </div>
  );
}
