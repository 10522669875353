import React from 'react';
import { ReactComponent as NamecheapLogo } from '../assets/logo-namecheap-alt.svg';

export default function PoweredByNamecheap() {
  return (
    <div
      className="relative z-10 md:absolute top-0 left-0 right-0 md:left-auto inline-flex items-baseline justify-center p-2 md:p-4 text-gray-200 md:text-gray-800 font-sans uppercase tracking-widest"
      style={{ WebkitFontSmoothing: 'antialiased' }}>
      <span className="text-xs">Powered by</span>{' '}
      <NamecheapLogo
        className="h-4 mr-2 ml-4 relative"
        style={{ top: '2px' }}
      />{' '}
      <span className="text-sm">Namecheap</span>
    </div>
  );
}
