import React from 'react';
import MaterialUIDialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Dialog(props) {
  return (
    <MaterialUIDialog
      keepMounted
      maxWidth={false}
      TransitionComponent={Transition}
      {...props}
    />
  );
}
