import React from 'react';
import { Helmet } from 'react-helmet';
import ReactDOM from 'react-dom';
import LoadingBar from './components/LoadingBar';
import OfferForm from './components/OfferForm';
import usePageData, { PageDataProvider } from './lib/usePageData';
import './fonts.css';
import './index.css';

const BuyItNowPage = React.lazy(() => import('./components/BuyItNowPage'));

function Main() {
  const { config, domain } = usePageData();
  if (!config) {
    return <LoadingBar className="hidden md:block" />;
  }
  return (
    <>
      <Helmet>
        <title>{domain} is for sale</title>
      </Helmet>
      {config.template === 'bin' ? <BuyItNowPage /> : <OfferForm />}
    </>
  );
}

ReactDOM.render(
  <PageDataProvider>
    <React.Suspense fallback={<LoadingBar className="hidden md:block" />}>
      <Main />
    </React.Suspense>
  </PageDataProvider>,
  document.getElementById('root'),
);
