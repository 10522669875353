import cx from 'classnames';
import React from 'react';
import { ReactComponent as ChevronRight } from '../assets/chevron-right.svg';

function Button(
  {
    arrowRight = false,
    children,
    className = '',
    type = 'button',
    variant,
    ...buttonProps
  },
  ref,
) {
  let iconRight;
  if (arrowRight) {
    iconRight = <ChevronRight className="opacity-50" height={28} width={28} />;
  }
  return (
    <button
      className={cx(
        className,
        'pill px-8 font-display font-semibold',
        'transition-colors duration-100',
        {
          'cursor-default': buttonProps.disabled,
          'opacity-50': variant === 'submit' && buttonProps.disabled,
          'bg-blue w-full lg:w-auto h-16 mt-8 md:mt-12 px-20 text-lg':
            variant === 'submit',
        },
      )}
      ref={ref}
      type={type}
      {...buttonProps}>
      {children}
      {iconRight && <span className="inline-flex ml-2 -mr-6">{iconRight}</span>}
    </button>
  );
}

export default React.forwardRef(Button);
