import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as Lightning } from '../assets/lightning.svg';
import { ReactComponent as Secure } from '../assets/secure.svg';
import { ReactComponent as Shield } from '../assets/shield.svg';
import { ReactComponent as Tick } from '../assets/tick.svg';
import { ReactComponent as Transfer } from '../assets/transfer.svg';
import { ReactComponent as Umbrella } from '../assets/umbrella.svg';

const blockDefs = {
  buyerProtection: [
    <Umbrella className="h-full" />,
    <FormattedMessage id="TRUST_BLOCK_BUYER_PROTECTION" />,
  ],
  paymentPlan: [
    <Tick className="h-full transform scale-90" />,
    <FormattedMessage id="TRUST_BLOCK_PAYMENTPLAN" />,
  ],
  registrar: [
    <Lightning className="h-full" />,
    <FormattedMessage id="TRUST_BLOCK_REGISTRAR" />,
  ],
  safeTransfer: [
    <Transfer className="h-full" />,
    <FormattedMessage id="TRUST_BLOCK_SAFE_TRANSFER" />,
  ],
  secure: [
    <Secure className="h-full" />,
    <FormattedMessage id="TRUST_BLOCK_SECURE" />,
  ],
  secureAlt: [
    <Shield className="h-full" />,
    <FormattedMessage id="TRUST_BLOCK_SECURE_ALT" />,
  ],
};

export default function TrustBlocks({ blocks = [] }) {
  return (
    <div className="md:flex justify-center lg:justify-between w-full max-w-lg md:max-w-none px-4 md:px-8 lg:px-0 text-gray-700 text-left text-sm sm:text-lg md:text-sm lg:text-base sm:leading-tight">
      {blocks.map((block, index) => (
        <div
          className="flex items-center md:w-1/3 lg:w-auto mx-6 mt-6 md:mt-0 md:mx-2 rounded-lg"
          key={index}>
          <div className="flex justify-center md:justify-end flex-shrink-0 w-12 h-12 sm:w-16 sm:h-16 md:h-12 md:w-12">
            {block[0]}
          </div>
          <div className="lg:max-w-56 pl-8 md:pl-4 lg:pr-4">{block[1]}</div>
        </div>
      ))}
    </div>
  );
}

TrustBlocks.blockDefs = blockDefs;
