import cx from 'classnames';
import fitty from 'fitty';
import React from 'react';
import { ReactComponent as ChevronLeft } from '../assets/chevron-left.svg';

export function Layout(props) {
  return (
    <div
      className={cx(
        props.className,
        'page md:min-h-screen text-center font-sans flex flex-col',
      )}>
      {props.onBack && (
        <button
          aria-label="Go back"
          className="absolute z-10 top-0 left-0 m-2 md:m-8"
          onClick={props.onBack}>
          <ChevronLeft height={48} width={48} />
        </button>
      )}
      {props.children}
    </div>
  );
}

export function LayoutTop({ className, ...props }) {
  return (
    <div
      className={cx(
        className,
        'relative flex flex-col items-center justify-center py-8 bg-gray-400',
      )}
      {...props}
    />
  );
}

export function LayoutBottom(props) {
  return (
    <div className={cx(props.className, 'bg-gray-450 w-full')}>
      {props.children}
    </div>
  );
}

export function LayoutHeading(props) {
  const [fontsLoaded, setFontsLoaded] = React.useState(false);
  React.useEffect(() => {
    document.fonts.ready.then(() => {
      fitty('.fitty', {
        maxSize: props.variant === 'domain' ? 100 : 80,
        multiLine: false,
      });
      setFontsLoaded(true);
    });
  }, [props.variant, fontsLoaded, setFontsLoaded]);
  return (
    <h1
      className={cx(
        props.className,
        'w-full max-w-4xl mx-auto text-5xl text-white text-shadow font-title leading-none',
        'transform scale-85 md:scale-100 origin-center',
        {
          invisible: !fontsLoaded,
        },
      )}
      style={{
        textShadow: '0px 1px 1px hsla(0deg, 0%, 0%, 0.1)',
      }}>
      <div className="w-full flex justify-center max-w-2xl mx-auto">
        <div className="fitty inline-block whitespace-no-wrap">
          {props.children}
        </div>
      </div>
    </h1>
  );
}

export function LayoutSubheading(props) {
  return (
    <div
      className={cx(
        props.className,
        'text-gray-700 font-display text-xl sm:text-2xl md:text-3xl',
      )}>
      {props.children}
    </div>
  );
}
