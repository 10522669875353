import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';

export default function Loading(props) {
  return (
    <LinearProgress
      classes={{ root: 'progress-root', bar: 'progress-bar' }}
      color="primary"
      {...props}
    />
  );
}
